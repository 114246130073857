<template>
    <v-app id="checkout">

        <!-- Checkout header -->
        <v-card flat>
            <v-card-title>
                <v-flex text-xs-center>
                    <h3 class="text-center font-weight-bold header-space">
                        Checkout

                        <v-btn
                            class="page_close_btn"
                            icon
                            to="/calendar"
                        >
                            <v-icon color="black" large>mdi-close</v-icon>
                        </v-btn>

                    </h3>
                </v-flex>
            </v-card-title>
        </v-card>
        <!-- End header -->

        <v-divider></v-divider>

        <div class="close_drawer text-center" v-if="client_select">
            <v-btn
                class="mx-2"           
                fab
                color="grey lighten-2"
                @click.stop="client_select = !client_select"
            >
            <v-icon>mdi-close</v-icon>
            </v-btn>
            <p class="mt-3">CLICK TO CLOSE</p>
        </div>

        <v-row no-gutters style="position:relative;">
            <!-- Left block -->
            <v-col cols="12" sm="6" md="8">
                <v-sheet height="690" class="grey lighten-4" style="overflow-y: scroll;">
                    <!-- Service details -->
                    <v-expansion-panels v-model="open_panel" class="checkout-expansion px-7 pt-6 pb-4">
                        <v-expansion-panel
                            v-for="(item,i) in service_details"
                            :key="i"
                        >
                            <v-expansion-panel-header class="py-7 px-7 d-block" expand-icon="">
                                <v-row :key="i">
                                    <v-col
                                        class="col-5_5"
                                    >
                                        <span v-if="!item.is_voucher" class="font-weight-bold fs-1_1 py-3 px-4 pl-0">1</span>
                                        <span v-if="item.is_voucher" class="font-weight-bold fs-1_1 py-3 px-4 pl-0">2</span>
                                    </v-col>
                                    <v-col
                                        cols="9"
                                        class="pl-0"
                                        v-if="!item.is_voucher"
                                    >
                                        <p class="font-weight-medium fs-1_1 mb-2">{{ item.item_name }}</p>
                                        <p v-if="!item.product" class="fs-1 text--disabled mb-0">{{ item.duration }}min with {{ item.staff_name }}</p>
                                        <p v-if="item.product" class="fs-1 text--disabled mb-0">{{ item.stock_balance }} in stock</p>
                                    </v-col>
                                    <v-col
                                        cols="9"
                                        class="pl-0"
                                        v-if="item.is_voucher"
                                    >
                                        <p class="font-weight-medium fs-1_1 mb-2">{{ $currency }}{{ item.actual_amount }} - {{ item.item_name }}</p>
                                        <p class="fs-1 text--disabled mb-0">Expires on {{ moment(item.expires_on).format('D MMM, Y') }}</p>
                                    </v-col>

                                    <v-col
                                        cols="2"
                                        class="text-right pr-0"
                                    >
                                        <p class="font-weight-medium fs-1_1 mb-1">
                                            {{ $currency }}{{ item.amount }}
                                            <v-btn
                                                x-small
                                                icon
                                                style="margin-top: -5px;margin-right: -15px;margin-left: 10px;"
                                                @click.native.stop
                                                @click="delete_service(i)"
                                            >
                                                <svg viewBox="0 0 18 18" width="12px" height="12px" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M17 1.914L16.086 1 9 8.086 1.914 1 1 1.914 8.086 9 1 16.086l.914.914L9 9.914 16.086 17l.914-.914L9.914 9z" fill="#e45a74"></path>
                                                </svg>
                                            </v-btn>
                                        </p>
                                        <s v-if="(open_panel == i) && (item.rate < oldPriceValue)" class="text--disabled" style="padding-right: 26px;">{{ $currency }}{{ oldPriceValue }}</s>
                                        <!-- @click="delete_service(i)" -->
                                    </v-col>
                                </v-row>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <v-row :key="i">
                                    <v-col
                                        class="col-5_5"
                                    >
                                        <span class="font-weight-bold fs-1_1 py-3 px-4 pl-0"></span>
                                    </v-col>
                                    <v-col
                                        cols="1"
                                        class="px-0"
                                    >
                                        <div class="subtitle-1 font-weight-medium">Quantity</div>
                                        <v-text-field
                                            :solo-inverted="item.product || item.is_voucher ? false : true"
                                            :solo="item.product || item.is_voucher ? true : false"
                                            v-model="item.qty"
                                            :disabled="item.product || item.is_voucher ? false : true"
                                            @input="checkQtyChange(i)"
                                        ></v-text-field>
                                    </v-col>

                                    <v-col
                                        cols="2"
                                        class="px-0 ml-4"
                                    >
                                        <div class="subtitle-1 font-weight-medium">Price</div>
                                        {{ open_panel == i ? setPriceValue(item.amount) : '' }}
                                        <v-text-field
                                            v-model="item.rate"
                                            solo
                                            :prepend-inner-icon="$currency"
                                            class="custom-prepend-icon"
                                            @input="checkPriceChange(item.rate, i)"
                                        ></v-text-field>
                                    </v-col>

                                    <!-- class="px-0 ml-4" -->
                                    <v-col
                                        cols="4"
                                        class="px-0 ml-4"
                                    >
                                        <div class="subtitle-1 font-weight-medium">Staff</div>
                                        <v-select
                                            v-model="item.staff"
                                            :items="staffs"
                                            item-text="employee_name"
                                            item-value="id"
                                            solo
                                        ></v-select>
                                    </v-col>

                                    <v-col
                                        cols="3"
                                        class="px-0 ml-4"
                                    >
                                        <div class="subtitle-1 font-weight-medium">Discount</div>
                                        <v-select
                                            v-if="item.rate == oldPriceValue"
                                            :value="'No discount'"
                                            :items="discount_list"
                                            :solo="item.is_voucher ? false : true"
                                            :solo-inverted="item.is_voucher ? true : false"
                                            :disabled="item.is_voucher ? true : false"
                                        ></v-select>
                                        <v-text-field
                                            v-if="item.rate != oldPriceValue && !item.is_voucher"
                                            :value="discount_text"
                                            :append-icon="'mdi-close-circle'"
                                            @click:append="clearDiscount(i)"
                                            solo
                                            class="discount_icon"
                                        ></v-text-field>
                                        <v-text-field
                                            v-if="item.rate != oldPriceValue && item.is_voucher"
                                            :value="discount_text"
                                            solo
                                            class="discount_icon"
                                            disabled
                                            solo-inverted
                                        ></v-text-field>
                                    </v-col>    

                                </v-row>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>
                    <!-- End service details -->

                    <!-- Bottom total -->
                    <div class="px-7">
                        <v-row>
                            <v-col
                                cols="5"
                            >
                                <div @click="add_item_dialog = !add_item_dialog" class="create_cli_btn full-underline ml-10">
                                    <v-icon class="mr-1" color="#037aff">mdi-plus</v-icon>
                                    <span>Add item to sale</span>
                                </div>
                            </v-col>

                            <v-col
                                cols="6"
                                class="pr-0"
                            >
                                <div class="d-flex flex-column">
                                    <div class="d-flex flex-row justify-space-between px-2">
                                        <p>Total</p>
                                        <p>{{ $currency }}{{ this.service_total_amount }}</p>
                                    </div>
                                
                                    <v-divider></v-divider>

                                    <div class="d-flex flex-row justify-space-between mb-4 px-2 mt-4">
                                        <span>Tax (15%)</span>
                                        <span>{{ $currency }}{{ tax }}</span>
                                    </div>
                                    <v-divider></v-divider>

                                    <div class="d-flex flex-row justify-space-between mb-4 px-2 mt-4">
                                        <span>Discount</span>
                                        <span>{{ $currency }}{{ discount }}</span>
                                    </div>
                                    <!-- Dont remove (start) -->
                                    <!-- <div v-if="tips_list.length > 0">
                                        <template v-for="(tip, i) in tips_list">
                                            <div  :key="'tip_div_'+i" class="d-flex flex-row justify-space-between px-2 mt-4 create_cli_btn full-underline">
                                                <span :key="'tip_info_'+i" class="create_cli_btn full-underline">Tip for {{ tip.staff_name }}</span>
                                                <span :key="'tip_amnt_'+i" style="text-decoration: none;margin-right: -29px;">
                                                    {{ $currency }}{{ tip.calculated_amount }} 
                                                    <span @click="delete_tip(i)" style="padding-left: 12px;">
                                                        <svg viewBox="0 0 18 18" width="12px" height="12px" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M17 1.914L16.086 1 9 8.086 1.914 1 1 1.914 8.086 9 1 16.086l.914.914L9 9.914 16.086 17l.914-.914L9.914 9z" fill="#e45a74"></path>
                                                        </svg>
                                                    </span>
                                                </span>
                                            </div>
                                        </template>
                                    </div> -->

                                    <!-- <div v-if="tips_list.length < staffs.length" class="d-flex flex-row justify-space-between mb-4 px-1 mt-4">
                                        <div @click="add_tip_dialog = !add_tip_dialog" class="create_cli_btn full-underline">
                                            <v-icon class="mr-1" color="#037aff">mdi-plus</v-icon>
                                            <span>Add tip</span>
                                        </div>
                                    </div> -->
                                    <!-- Till here Dont remove -->

                                    <v-divider v-if="pay_types.length > 0"></v-divider>

                                    <div class="mt-4 mb-4" v-if="pay_types.length > 0">
                                        <template v-for="(pays, i) in pay_types">
                                            <div :key="'pays_type_div_'+i" class="d-flex flex-row justify-space-between px-2">
                                                <p class="mb-0" :key="'pays_type_'+i">{{ pays.type }}</p>
                                                <p class="mb-0" :key="'pays_amnt_'+i" style="margin-right: -29px;">
                                                    {{ $currency }}{{ pays.amount }}
                                                    <span @click="delete_pay(i)" style="cursor:pointer;padding-left: 12px;">
                                                        <svg viewBox="0 0 18 18" width="12px" height="12px" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M17 1.914L16.086 1 9 8.086 1.914 1 1 1.914 8.086 9 1 16.086l.914.914L9 9.914 16.086 17l.914-.914L9.914 9z" fill="#e45a74"></path>
                                                        </svg>
                                                    </span>
                                                </p>
                                            </div>
                                        </template>
                                    </div>

                                    <v-divider v-if="applied_voucher.length > 0"></v-divider>

                                    <div class="mt-4 mb-4" v-if="applied_voucher.length > 0">
                                        <!-- <template v-for="(applied, i) in applied_voucher"> -->
                                            <div class="d-flex flex-row justify-space-between px-2">
                                                <p class="mb-0">Voucher ({{ applied_voucher[0].code }})</p>
                                                <p class="mb-0" style="margin-right: -29px;">
                                                    {{ $currency }}{{ applied_voucher[0].amount }}
                                                    <span @click="remove_voucher()" style="padding-left: 12px;cursor:pointer;">
                                                        <svg viewBox="0 0 18 18" width="12px" height="12px" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M17 1.914L16.086 1 9 8.086 1.914 1 1 1.914 8.086 9 1 16.086l.914.914L9 9.914 16.086 17l.914-.914L9.914 9z" fill="#e45a74"></path>
                                                        </svg>
                                                    </span>
                                                </p>
                                            </div>
                                        <!-- </template> -->
                                    </div>

                                    <v-divider></v-divider>

                                    <div class="d-flex flex-row justify-space-between mt-4 px-2">
                                        <p class="text-h6 font-weight-bold">Balance</p>
                                        <p class="text-h6 font-weight-bold">{{ $currency }}{{ this.balance_amount }}</p>
                                    </div>
                                </div>
                            </v-col>
                        </v-row>
                    </div>
                    <!-- End bottom total -->

                </v-sheet>
            </v-col>
            <!-- End left block -->

            <!-- Right block -->
            <v-col cols="6" md="4">
                <v-card link flat class="pa-6 pb-5" @click="client_select = true; client_search = ''">
                    <div class="d-flex align-center">
                        <v-avatar
                            color="grey lighten-2"
                            size="60"
                            class="mr-3"
                            v-if="selected_client.customer_name == 'Default Customer'"
                        >
                            <span style="width: 32px;height: 32px;">
                                <svg viewBox="0 0 20 22" xmlns="http://www.w3.org/2000/svg"><g fill="#FFF" stroke="#101928"><path d="M.5 21c0-5.5313 4.1043-9.5 9.5-9.5s9.5 3.9687 9.5 9.5v.5H.5V21z" stroke-linecap="round" stroke-linejoin="round"></path><path d="M15.5 6c0-3.0374-2.4626-5.5-5.5-5.5S4.5 2.9626 4.5 6s2.4626 5.5 5.5 5.5 5.5-2.4626 5.5-5.5z"></path></g></svg>
                            </span>
                        </v-avatar>

                        <v-avatar class="mr-3" size="60" v-if="selected_client.customer_name != 'Default Customer'">
                            <v-icon color="#000" class="grey lighten-4" >
                                {{ selected_client.customer_name.charAt(0) }}
                            </v-icon>
                        </v-avatar>

                        <div class="d-flex flex-column">
                            <div class="font-weight-medium fs-1_1">
                                {{ selected_client.customer_name }}
                            </div>
                            <div class="text--disabled text-subtitle-1" style="line-height: 17px;">
                                {{ selected_client.email_id }}
                            </div>
                        </div>
                        

                        <div class="ml-auto">
                            <v-icon class="float-right">mdi-chevron-right</v-icon>
                        </div>
                    </div>
                </v-card>

                <v-divider></v-divider>

                <div style="position : relative;"> 
                <!-- height: 588px -->
                <!-- Pay buttons -->
                <v-card
                    color="white"
                    flat
                    height="500"
                    tile
                >
                    <div class="pt-6 pa-7">

                        <div>
                            <div class="subtitle-1 text-center font-weight-medium mb-1">Pay</div>
                            <v-text-field
                                v-model="pay_amount"
                                height="75"
                                solo
                                :prepend-inner-icon="$currency"
                                class="pay-big-icon"
                            ></v-text-field>
                        </div>

                        <v-row>
                            <v-col cols="6" class="pb-1" :key="'pay_mode_col_'+i" v-for="(mode, i) in payment_modes">
                                <v-btn
                                    :key="'pay_mode_'+i"
                                    x-large
                                    color="#101928"
                                    dark
                                    height="65px"
                                    class="mr-2 text-capitalize"
                                    style="letter-spacing: 0.5px;"
                                    block
                                    @click="payment_type(mode.name)"
                                >
                                    {{ mode.name }}
                                </v-btn>
                            </v-col>

                            <v-col cols="6">
                                <v-btn
                                    x-large
                                    color="#101928"
                                    dark
                                    height="65px"
                                    class="text-capitalize pt-1"
                                    block
                                    style="letter-spacing: 0.5px;"
                                    @click="voucher_dialog = !voucher_dialog;"
                                >
                                    Voucher
                                </v-btn>
                            </v-col>
                        </v-row>

                    </div>
                </v-card>
                <!-- End pay buttons -->

                <v-divider></v-divider>

                <!-- More option button -->
                <v-card flat>
                    <div class="ma-5 text-center">
                        <v-menu top offset-y rounded="0">
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    v-bind="attrs"
                                    v-on="on"
                                    large
                                    color="white"
                                    elevation="1"
                                    class="app_btn"
                                    block
                                >
                                    <span class="text-capitalize font-checkout">More options</span>
                                    <v-icon dense class="pl-3">mdi-chevron-down</v-icon>
                                </v-btn>
                            </template>
                            <v-list class="pa-0" dense>
                                <template v-for="(item, index) in more_options">
                                    <v-list-item
                                        :key="'more_opt_'+index"
                                        link
                                        :class="item.text_color"
                                        @click="more_option_click(item.value)"
                                    >
                                        <v-list-item-title class="text-center app_font" link>
                                            {{ item.label }}
                                        </v-list-item-title>
                                    </v-list-item>
                                    <v-divider
                                        v-if="index < more_options.length - 1"
                                        :key="'more_opt_div_'+item.value"
                                    ></v-divider>
                                </template>
                            </v-list>
                        </v-menu>


                    </div>
                </v-card>
                <!-- End more option button -->

                <!-- Payment drawer -->
                <v-navigation-drawer
                    v-model="payment_drawer"
                    absolute
                    temporary
                    right
                    width="490px"
                    class="bx-shadow-none"
                >
                    <v-card
                        color="white"
                        flat
                        height="422"
                        tile
                    > 
                        <v-container class="px-15 py-7 mt-16" fluid>
                            <v-layout align-center justify-center>
                                <div class="text-md-center">
                                    <p class="custom_search_icon">
                                        <v-icon size="48px">$vuetify.icons.PaymentSvg</v-icon>
                                    </p>
                                    <p>Full payment has been added</p>
                                    
                                </div>
                            </v-layout>
                        </v-container>
                        <v-container class="px-10">
                            <v-btn
                                class="express_checkout custom-transform-class font-weight-medium"
                                large
                                block
                                dark
                                color="#101928"
                                @click="CompleteSale()"
                            >
                                Complete Sale
                            </v-btn>
                            <div @click="back_to_payments()" class="create_cli_btn full-underline text-center py-5">
                                <v-icon class="mr-1" color="#037aff">mdi-chevron-left</v-icon>
                                <span>Back to payments</span>
                            </div>
                        </v-container>
                    </v-card>
                </v-navigation-drawer>
                <!-- End payment drawer -->

                </div>
            </v-col>
            <!-- End right block -->

            <!-- Search client popup -->
            <v-navigation-drawer
                v-model="client_select"
                absolute
                temporary
                right
                width="490px"
            >
                <!-- Search box -->
                <v-col class="pb-0" cols="12" md="12" >
                    <v-card flat>
                        <div class="pa-3 pb-0">
                            <v-text-field
                                solo
                                placeholder="Search Client"
                                prepend-inner-icon="mdi-magnify"
                                v-model="client_search"
                                :append-icon="client_search != '' ? 'mdi-close-circle' : ''"
                                @click:append="client_search = ''"
                                class="custom_append_icon"
                            ></v-text-field>
                        </div> 
                    </v-card>
                </v-col>     
                <!-- End search box -->     

                <v-divider></v-divider>

                <div class="text-center pa-3">
                    <div @click="create_client_page()" class="create_cli_btn">
                        <v-icon class="mr-1" color="#037aff">mdi-plus</v-icon>
                        <span class="create_cli_text">Create new client</span>
                    </div>
                </div>

                <v-divider></v-divider>

                <v-list v-if="Object.keys(search_client_result).length != 0" two-line class="pt-0">
                    <template 
                        v-for="(item, i) in search_client_result"
                    >
                        <v-list-item :key="item.id" link @click="clientClick(item)">
                            <v-list-item-avatar size="60">
                                <v-icon color="#000" class="grey lighten-4" >
                                    {{ item.customer_name.charAt(0) }}
                                </v-icon>
                            </v-list-item-avatar>

                            <v-list-item-content>
                                <v-list-item-title>{{ item.customer_name }}</v-list-item-title>

                                <v-list-item-subtitle>{{ item.email_id }}</v-list-item-subtitle>

                                <v-list-item-subtitle>{{ item.phone_number }}</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                        <v-divider :key="i"></v-divider>
                    </template>
                </v-list>

                <v-container v-if="Object.keys(search_client_result).length == 0" class="px-15 py-14" fluid>
                    <v-layout align-center justify-center>
                        <div class="text-md-center">
                            <p class="custom_search_icon">
                                <v-icon size="48px">$vuetify.icons.SearchSvg</v-icon>
                            </p>
                            <p>No clients found</p>
                        </div>
                    </v-layout>
                </v-container>

            </v-navigation-drawer>
            <!-- End Search client popup -->

        </v-row>
        <!-- Add item dialog -->
        <v-dialog
            v-model="add_item_dialog"
            width="575"
            persistent
            transition="dialog-bottom-transition"
            class="top-dialog add-item-dialog"
        >
            <add-item :item_dialog="add_item_dialog" :checkout_items="checkout_items" @update-dialog="updateDialog" @add-service="addService" @add-product="addProduct" @add-voucher="addVoucher"></add-item>
        </v-dialog>
        <!-- End add item dialog -->

        <!-- Add tip dialog -->
        <v-dialog
            v-model="add_tip_dialog"
            width="448"
            persistent
            transition="dialog-bottom-transition"
            class="top-dialog"
        >
            <v-card
                class="mx-auto"
            >
                <v-card-title class="p-0 text-center">
                    <v-app-bar class="item_dialog_title" color="white" flat>
                        <div></div>
                        <v-app-bar-title class="text-h6 font-weight-bold">Add Tip</v-app-bar-title>
                        <v-btn
                            icon
                            @click="add_tip_dialog = !add_tip_dialog"
                        >
                            <v-icon large>mdi-close</v-icon>
                        </v-btn>
                    </v-app-bar>
                </v-card-title>

                <v-divider></v-divider>

                <v-card-text class="pa-6">
                    <v-row>
                        <v-col
                            cols="12"
                            class="pb-0"
                        >
                            <label class="dialog_label">Tip Amount ({{(tip_type == 1) ? $currency : '' }}{{ tip_amount_c }}{{ (tip_type == 0) ? '%' : '' }})</label>

                            <v-form
                                ref="tipform"
                            >
                            <v-row>
                                <v-col
                                    cols="8"
                                    class="pb-0 pr-0"
                                >
                                    <v-text-field
                                        class="custom-prepend-icon"
                                        solo
                                        :prepend-inner-icon="(tip_type == 0) ? $currency : '%'"
                                        v-model="tip_amount"
                                        :error="error"
                                        :rules="[rules.required,rules.number]"
                                        @input="tip_amount_calc()"
                                    ></v-text-field>
                                </v-col>

                                <v-col
                                    cols="4"
                                    class="pb-0 pr-0"
                                >
                                    <v-btn-toggle active-class="tip_type_clr" background-color="white" v-model="tip_type" @change="tip_amount_calc()">
                                        <v-btn class="bg-white fs-1_1" width="60px">
                                            {{ $currency }}
                                        </v-btn>
                                        <!-- color="white" -->
                                        <v-btn class="bg-white fs-1_1"  width="60px">
                                            %
                                        </v-btn>
                                    </v-btn-toggle>
                                </v-col>
                                <v-col
                                    cols="12"
                                    class="pt-0 pb-0"
                                >
                                    <label class="dialog_label">Staff Tipped</label>
                                    <v-select
                                        :items="not_tipped_staffs"
                                        item-text="employee_name"
                                        item-value="id"
                                        solo
                                        v-model="tipped_staff"
                                        placeholder="Select staff"
                                        :error="error"
                                        :rules="[rules.required]"
                                    ></v-select>
                                </v-col>
                                
                            </v-row>
                            </v-form>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-btn
                        block
                        large
                        color="#101928"
                        dark
                        class="text-capitalize"
                        @click="add_tip()"
                    >
                        Save
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!-- End add tip dialog -->

        <!-- Redeem voucher dialog -->
        <v-dialog
            v-model="voucher_dialog"
            width="448"
            persistent
            transition="dialog-bottom-transition"
            class="top-dialog"
        >
            <v-card
                class="mx-auto"
            >
                <v-card-title class="p-0 text-center">
                    <v-app-bar class="item_dialog_title" color="white" flat>
                        <div></div>
                        <v-app-bar-title class="text-h6 font-weight-bold">Redeem Voucher</v-app-bar-title>
                        <v-btn
                            icon
                            @click="voucher_dialog = !voucher_dialog"
                        >
                            <v-icon large>mdi-close</v-icon>
                        </v-btn>
                    </v-app-bar>
                </v-card-title>

                <v-divider></v-divider>

                <div class="pa-6">
                    <v-row>
                        <v-col
                            cols="12"
                            class="pb-0"
                        >
                            <v-text-field
                                v-model="voucher_code"
                                solo
                                placeholder="Enter Voucher Code"
                                prepend-inner-icon="mdi-magnify"
                                :append-icon="voucher_code != '' ? 'mdi-close-circle' : ''"
                                @click:append="voucher_code = ''"
                                class="discount_icon"
                            ></v-text-field>
                               <center> <v-btn depressed color="primary" @click="check_voucher()" >Validate</v-btn></center>
                        </v-col>
                        <v-col v-if="voucher_code == ''" cols="12">
                            <div class="pa-9 d-flex flex-column justify-center align-center">
                                <p>
                                    <v-icon size="48px">$vuetify.icons.CheckVoucherSvg</v-icon>
                                </p>
                                <p class="text--h6 text-center">Check an existing voucher status & balance</p>
                            </div>
                        </v-col>
                    </v-row>
                </div>

                <div v-if="voucher_exists" class="d-flex flex-grow-1 flex-shrink-0" style="position: relative;max-width: 100%;">
                    <div class="d-flex flex-column flex-grow-1 flex-shrink-0" style="max-width: 100%;">
                        <div class="d-flex flex-column pb-10 px-5" style="border-radius: 3px;background-color: #fff;transition: background-color .2s;">
                            <div class="d-flex flex-column align-center" style="border: 1px solid #eef0f2;border-radius: 10px;background-color: #fbfbfb;box-shadow: 0 2px 5px 0 rgb(164 173 186 / 25%);padding: 30px 60px;min-height: 150px;min-width: 100px;position: relative;">
                                <h2 class="mb-5 font-weight-regular">Outstanding {{ $currency }}{{ voucher_outstanding }}</h2>
                                <div class="d-flex flex-column" style="padding: 0px 16px 0px;">
                                    <div class="subtitle-1 font-weight-medium">Redemption Amount</div>
                                    <v-text-field
                                        v-model="redemption_amount"
                                        solo
                                        :prepend-inner-icon="$currency"
                                        class="custom-prepend-icon"
                                    ></v-text-field>
                                </div>

                                <v-btn
                                    x-large
                                    color="#101928"
                                    dark
                                    height="63px"
                                    class="text-capitalize pt-1"
                                    block
                                    @click="redeem_voucher_apply()"
                                >
                                    Redeem Voucher
                                </v-btn>
                                <div style="position: absolute;left: -7px;top: -7px;overflow: hidden;padding: 34px 0;">
                                    <div style="position: relative;left: -56px;top: -16px;color: #101928;background-color: #f7f7f8;text-transform: uppercase;text-align: center;font-size: 16px;font-weight: 500;height: 34px;line-height: 34px;width: 204px;transform: rotate(-35deg);">
                                        Gift
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <v-container v-if="!voucher_exists && voucher_code != ''" class="px-15 py-8 pb-16" fluid>
                    <v-layout align-center justify-center>
                        <div class="text-md-center">
                            <p class="custom_search_icon">
                                <v-icon size="48px">$vuetify.icons.SearchSvg</v-icon>
                            </p>
                            <p>No vouchers found</p>
                        </div>
                    </v-layout>
                </v-container>
                
            </v-card>
        </v-dialog>
        <!-- End add item dialog -->

        <!-- Invoice dialog -->
        <v-dialog
            v-model="invoice_dialog"
            width="448"
            persistent
            transition="dialog-bottom-transition"
            class="top-dialog"
        >
            <v-card
                class="mx-auto"
            >
                <v-card-title class="p-0 text-center">
                    <v-app-bar class="item_dialog_title" color="white" flat>
                        <div></div>
                        <v-app-bar-title class="text-h6 font-weight-bold">Invoice Details</v-app-bar-title>
                        <v-btn
                            icon
                            @click="invoice_dialog = !invoice_dialog"
                        >
                            <v-icon large>mdi-close</v-icon>
                        </v-btn>
                    </v-app-bar>
                </v-card-title>

                <v-divider></v-divider>

                <v-card-text class="pa-6">
                    <v-row>
                        <v-col
                            cols="12"
                        >
                            <label class="dialog_label">Payment received by</label>

                            <v-row>
                                <v-col
                                    cols="12"
                                    class="pb-0"
                                >
                                    <v-select
                                        :items="staffs"
                                        item-text="employee_name"
                                        item-value="employee_name"
                                        solo
                                        v-model="invoice_staff"
                                    ></v-select>
                                </v-col>

                                <v-col
                                    cols="12"
                                    class="pt-0 pb-0"
                                >
                                    <label class="dialog_label">Invoice notes</label>
                                    <v-textarea
                                        counter="250"
                                        v-model="invoice_notes"
                                        auto-grow
                                        solo
                                        rows="1"
                                        >
                                    </v-textarea>
                                </v-col>
                                
                            </v-row>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-btn
                        block
                        large
                        color="#101928"
                        dark
                        class="text-capitalize"
                        @click="invoice_dialog = !invoice_dialog"
                    >
                        Save
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!-- End invoice dialog -->

        <!-- Confirm unpaid tips dialog -->
        <v-dialog
            v-model="unpaid_tip_dialog"
            width="448"
            persistent
            transition="dialog-bottom-transition"
            class="top-dialog"
        >
            <v-card
                class="mx-auto"
            >
                <v-card-title class="p-0 text-center">
                    <v-app-bar class="item_dialog_title" color="white" flat>
                        <div></div>
                        <v-app-bar-title class="text-h6 font-weight-bold">Confirm Unpaid Tips</v-app-bar-title>
                        <v-btn
                            icon
                            @click="unpaid_tip_dialog = !unpaid_tip_dialog"
                        >
                            <v-icon large>mdi-close</v-icon>
                        </v-btn>
                    </v-app-bar>
                </v-card-title>

                <v-divider></v-divider>

                <div class="pa-6">
                    <v-row>
                        <v-col
                            cols="12"
                            class="pb-0"
                        >
                            <p class="text-body-1 text--secondary">This invoice must be fully paid in order for tips to be applied. Otherwise tips will be removed. Do you really want to proceed and remove tips?</p>
                        </v-col>
                    </v-row>
                </div>

                <v-divider></v-divider>
                <v-card-actions>
                    <v-btn
                        block
                        large
                        color="#101928"
                        dark
                        class="text-capitalize"
                        @click="unpaid_tip_dialog = !unpaid_tip_dialog"
                    >
                        Yes, Proceed
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!-- End confirm unpaid tips dialog -->
    </v-app>
</template>

<script>
import AddItem from '../popups/AddItem';
import CommonApi from '@/services/CommonApi'
import moment from 'moment'

export default {
  components: { AddItem },
    data: () => ({
        more_options: [
            { label: 'Save Unpaid', value: 1 },
            { label: 'Invoice Details', value: 2 }
        ],
        add_item_dialog: false,
        add_tip_dialog: false,
        staffs : [],
        client_select : false,
        clients: [],
        selected_client : { id : '', customer_name : '', email_id : '' },
        payment_drawer : false,
        voucher_dialog : false,
        service_details : [],
        service_total_amount: 0,
        balance_amount : 0,
        pay_amount : 0,
        open_panel : 0,
        discount_list : [ 'No discount' ],
        oldPriceValue : 0,
        priceSetCount : 0,
        discount_text : '',
        // services_list : [],
        checkout_items : [],
        tip_type: 0,
        tip_amount : 0,
        tipped_staff : '',
        tips_list : [],
        not_tipped_staffs : [],
        tip_amount_c : 0,
        error: false,
        rules: {
            required: value => !!value || 'This field is required.',
            number : value => { 
                if (value <= 0) {
                    return 'This field is required.';
                } else if(isNaN(value)) {
                    return 'Enter valid number.';
                } else {
                    return true;
                }
            },
        },
        pay_types : [],
        invoice_dialog : false,
        invoice_staff : '', 
        invoice_notes : '',
        unpaid_tip_dialog : false,

        payment_modes : [],
        moment : moment,
        redemption_amount : 0,
        voucher_outstanding : 0,
        voucher_code : '',
        voucher_exists : false,
        applied_voucher : [],
        tax : 0,
        discount: 0,
        client_search : ''
    }),
    mounted() {
        this.getDropdownsList();
        this.getCheckoutItems();
        this.getPaymentModes();
        this.getAppointmentDetails();
    },
    computed: {
        search_client_result() {
			if(this.client_search){
				let newObj = {};
                let search_text = this.client_search.toLowerCase();
				for (let key in this.clients) {
					if (this.clients[key].customer_name.toString().toLowerCase().match(search_text)) {
						newObj[key] = this.clients[key];
					} else if(this.clients[key].email_id != null && this.clients[key].email_id.toString().toLowerCase().match(search_text)) {
                        newObj[key] = this.clients[key];
                    } else if(this.clients[key].phone_number != null && this.clients[key].phone_number.toString().toLowerCase().match(search_text)) {
                        newObj[key] = this.clients[key];
                    }
				}
				return newObj;
			}else{
				return this.clients;
			}
		}
    },
    watch: {
        'open_panel': function(){
            this.priceSetCount = 0;
        }
    },
    methods : {
        remove_voucher() {
            this.applied_voucher = [];

            if(this.payment_drawer) {
                this.payment_drawer = false;
            }

            let total = 0;
            this.tips_list.forEach(val => {
                total += val.calculated_amount;
            })

            this.balance_amount = parseFloat(this.service_total_amount) + total + this.tax - this.discount;
            this.pay_amount = this.balance_amount;
        },
        redeem_voucher_apply() {
            this.voucher_dialog = false;
            this.applied_voucher = [ { code : this.voucher_code, amount : this.redemption_amount } ];
            this.balance_amount = this.balance_amount - this.redemption_amount;

            if(this.balance_amount == 0) {
                this.payment_drawer = !this.payment_drawer;  
            }
            this.voucher_code = '';
            this.redemption_amount = 0;
            this.voucher_outstanding = 0;
            this.voucher_exists = false;
            this.pay_amount = this.balance_amount;
        },
        check_voucher() {
            let post_data = new FormData();
            post_data.append("card_code", this.voucher_code)
            CommonApi.post_data('innosoft_salon.api.validate_gift_card_coupon', post_data)
            .then(res => {
                if (res.status_code == 200) {
                    this.voucher_outstanding = res.data.amount;
                    this.voucher_exists = true;
                    if(res.data.amount > this.pay_amount) {
                        this.redemption_amount = this.pay_amount;
                    } else {
                        this.redemption_amount = res.data.amount;
                    }
                } else {
                    this.voucher_exists = false;
                }
            })
        },
        updateDialog(e) {
           this.add_item_dialog = e; 
        },
        create_client_page() {
            this.$router.push('/create_client');
        },
        async getDropdownsList() {
            let branch_param = { branch: localStorage.getItem("branch") };
            branch_param = new URLSearchParams(branch_param);
            await CommonApi.get_data('innosoft_salon.api.get_all_data', branch_param)
            .then(res => {
                if (res.status_code == 200) {
                    this.clients = res.data.client;
                    this.staffs = res.data.staff_list;
                    this.not_tipped_staffs = res.data.staff_list;
                    // this.services_list = res.data.service_list;

                    this.invoice_staff = res.data.staff_list[0].employee_name;
                }
            })
        },
        async getCheckoutItems() {
            await CommonApi.get_data('innosoft_salon.api.get_checkout_additional_item_details')
            .then(res => {
                console.log(res)
                this.checkout_items = res.data;
                // console.log("this.checkout_items ", this.checkout_items)
            })
        },
        async getPaymentModes() {
            await CommonApi.get_data('innosoft_salon.api.get_mode_of_payment')
            .then(res => {
                if (res.status_code == 200) {
                    this.payment_modes = res.data;
                }
            })
        },
        getAppointmentDetails() {
            this.appointment_id = this.$route.params.appointment_id;
            // let param = { appointment_id: this.appointment_id };
            // const params = new URLSearchParams(param);
            const post_data = new FormData();
            post_data.append("appointment", this.appointment_id)
            CommonApi.post_data('innosoft_salon.api.checkout', post_data)
            .then(res => {
                if (res.status_code == 200) {
                    this.service_details = res.data.items;

                    this.open_panel = this.service_details.length - 1;

                    this.service_total_amount = res.data.net_total;
                    this.tax = res.data.tax_total;
                    this.discount = res.data.discount_amount;
                    // let cal_balance_amnt = res.data.grand_total - res.data.discount_amount;
                    // parseFloat(cal_balance_amnt).toFixed(2);
                    this.balance_amount = res.data.grand_total;
                    this.pay_amount = this.balance_amount;
                    this.selected_client = {
                        id : res.data.customer,
                        customer_name : res.data.customer_name,
                        email_id : ''
                    }
                }
            })
        },
        addService(event) {
            let service_data = {};

            let staff_id = this.$route.params.employeeId;
            service_data.staff = staff_id;
            let staff_name = this.staffs.find(o => o.id === staff_id).employee_name;

            service_data.actual_amount = event.rate;
            service_data.amount = event.rate;
            service_data.discount_amount = 0;
            service_data.rate = event.rate;

            service_data.duration = event.duration;

            service_data.staff_name = staff_name;
            service_data.item_name = event.id;
            service_data.service = event.id;
            service_data.item_code = event.item_code;
            service_data.qty = 1;
            this.service_details.push(service_data);

            this.calculate_total_from_popup(service_data);
        },
        addProduct(event) {
            let product_data = {};

            let staff_id = this.$route.params.employeeId;
            product_data.staff = staff_id;
            let staff_name = this.staffs.find(o => o.id === staff_id).employee_name;

            product_data.actual_amount = event.price;
            product_data.amount = event.price;
            product_data.discount_amount = 0;
            product_data.rate = event.price;

            product_data.duration = '';

            product_data.staff_name = staff_name;
            product_data.item_name = event.item_name;
            product_data.product = event.id;
            product_data.item_code = event.item_code;
            product_data.qty = 1;
            product_data.stock_balance = event.stock_balance;
            this.service_details.push(product_data)

            this.calculate_total_from_popup(product_data);
        },
        addVoucher(event) {
            let voucher_data = {};

            let staff_id = this.$route.params.employeeId;
            voucher_data.staff = staff_id;
            let staff_name = this.staffs.find(o => o.id === staff_id).employee_name;

            if(event.actual_amount) {
                voucher_data.actual_amount = event.actual_amount;
            } else {
                voucher_data.actual_amount = event.amount;
            }
            
            voucher_data.amount = event.amount;
            voucher_data.discount_amount = 0;
            voucher_data.rate = event.amount;

            voucher_data.duration = '';

            voucher_data.staff_name = staff_name;
            voucher_data.item_name = event.gift_card_title;
            voucher_data.voucher = event.id;
            voucher_data.item_code = event.item_code;
            voucher_data.qty = 1;
            voucher_data.expires_on = event.end_date;
            voucher_data.is_voucher = true;
            this.service_details.push(voucher_data)

            this.service_total_amount = this.service_total_amount + event.amount;
            this.balance_amount = this.balance_amount + event.amount;
            this.pay_amount = this.balance_amount;

            this.open_panel++;
            this.add_item_dialog = false;
        },
        calculate_total_from_popup(event) {
            this.service_total_amount = parseFloat(this.service_total_amount) + parseFloat(event.rate);
            this.balance_amount = this.balance_amount + parseFloat(event.rate);
            this.pay_amount = this.balance_amount;

            this.open_panel++;
            this.add_item_dialog = false;
        },
        delete_service(index) {
            this.$swal({
                title: 'Are you sure?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes, delete it!'
            }).then((result) => {
                if (result.value) {
                    this.service_details.splice(index, 1);
                    this.open_panel = this.service_details.length - 1;

                    let totals = 0;
            
                    this.service_details.forEach(val => {
                        if(val.rate && val.rate > 0) {
                            totals += parseFloat(val.rate) * parseFloat(val.qty);
                        }
                    })
                    
                    this.service_total_amount = totals;
                    this.balance_amount = totals + this.tax - this.discount;

                    this.pay_amount = this.balance_amount;
                }
            });
        },
        delete_tip(index) {
            this.tips_list.splice(index, 1);
        
            let staff_ids = [];
            this.tips_list.forEach(val => {
                staff_ids.push(val.staff_id);
            })

            this.not_tipped_staffs = [];

            this.staffs.forEach(val => {
                if(!staff_ids.includes(val.id)) {
                    this.not_tipped_staffs.push(val);
                }
            })

            let total = 0;
            this.tips_list.forEach(val => {
                total += val.calculated_amount;
            })

            this.balance_amount = parseFloat(this.service_total_amount) + total + this.tax - this.discount;
            this.pay_amount = this.balance_amount;
        },
        tip_amount_calc() {
            if(!isNaN(this.tip_amount)) {
                let calculated_amount = 0;
                if(this.tip_amount != 0) {
                    if(this.tip_type == 1) {
                        calculated_amount = parseInt(this.tip_amount) / 100 * parseInt(this.service_total_amount);
                    } else {
                        calculated_amount = parseInt(this.tip_amount) / parseInt(this.service_total_amount) * 100;
                    }
                }
                this.tip_amount_c = calculated_amount.toFixed(2);
            }
        },
        add_tip() {
            if(this.$refs.tipform.validate()) {
                let staff_name = this.staffs.find(o => o.id === this.tipped_staff).employee_name;

                let calculated_amount = 0;
                if(this.tip_type == 0) {
                    calculated_amount = parseInt(this.tip_amount);
                } else {
                    calculated_amount = parseInt(this.tip_amount) / 100 * parseInt(this.service_total_amount);
                }
                
                let tip_data = { 
                    staff_name : staff_name, 
                    staff_id : this.tipped_staff, 
                    tip_amount : this.tip_amount, 
                    calculated_amount : calculated_amount, 
                    tip_type : this.tip_type 
                }
                this.tips_list.push(tip_data);
                
                let total = 0;
                this.tips_list.forEach(val => {
                    total += val.calculated_amount;
                })

                this.not_tipped_staffs = this.not_tipped_staffs.filter((item) => item.id !== this.tipped_staff);

                this.balance_amount = parseFloat(this.service_total_amount) + total + this.tax - this.discount;
                this.pay_amount = this.balance_amount;

                this.reset_tip_dialog();
                this.pay_types = [];

                this.add_tip_dialog = false;
            }
        },
        payment_type(type) {
            let found = this.pay_types.findIndex((el => el.type == type));
            if(found == -1) {
                let pay = { type: type, amount : this.pay_amount };
                this.pay_types.push(pay);
            } else {
                this.pay_types[found].amount = parseInt(this.pay_types[found].amount) + parseInt(this.pay_amount);
            }

            let total_pay_amount = 0;
            this.pay_types.forEach(val => {
                total_pay_amount += parseFloat(val.amount);
            })

            let total_tips = 0;
            this.tips_list.forEach(val => {
                total_tips += val.calculated_amount;
            })

            let amount_to_pay = this.service_total_amount + total_tips + this.tax - this.discount;

            if(total_pay_amount >= amount_to_pay) {
                this.balance_amount = 0;
                this.payment_drawer = !this.payment_drawer;                
            } else {               
                this.balance_amount = amount_to_pay - total_pay_amount;
                this.pay_amount = this.balance_amount;

                // this.payment_drawer = !this.payment_drawer;
            } 
        },
        reset_tip_dialog() {
            this.tip_type = 0;
            this.tip_amount = 0;
            this.tipped_staff = '';
        },
        clientClick(selected_client) {
            this.selected_client = selected_client;
            this.client_select = false;
        },
        setPriceValue(price) {
            if(this.priceSetCount < 1) {
                this.oldPriceValue = price;
                this.priceSetCount++;
            }
        },
        checkPriceChange(price, i) {
            let totals = 0;
            
            this.service_details.forEach(val => {
                if(val.rate && val.rate > 0) {
                    totals += parseFloat(val.rate) * parseFloat(val.qty);
                }
            })

            this.service_details[i].amount = this.service_details[i].rate * this.service_details[i].qty;
            
            this.service_total_amount = totals;
            this.balance_amount = totals + this.tax - this.discount;

            this.pay_amount = this.balance_amount;

            let text = '';
            let price_update = 0;
            if(price < this.oldPriceValue) {
                price_update = this.oldPriceValue - price;
                text = 'Manual '+this.$currency +' '+price_update;
            } else if(price > this.oldPriceValue) {
                price_update = price - this.oldPriceValue;
                text = 'Increase '+this.$currency+' '+price_update;
            }

            this.discount_text = text;
        },
        checkQtyChange(i) {
            let totals = 0;
            
            this.service_details.forEach(val => {
                if(val.rate && val.rate > 0) {
                    totals += parseFloat(val.qty) * parseFloat(val.rate);
                }
            })

            this.service_details[i].amount = this.service_details[i].rate * this.service_details[i].qty;
            
            this.service_total_amount = totals;
            this.balance_amount = totals + this.tax - this.discount;

            this.pay_amount = this.balance_amount;
        },
        clearDiscount(index) {
            this.service_details[index].rate = this.oldPriceValue;
            this.service_details[index].amount = this.service_details[index].qty * this.oldPriceValue;

            let totals = 0;
            this.service_details.forEach(val => {
                totals += parseFloat(val.rate);
            })
            this.service_total_amount = totals;
            this.balance_amount = totals + this.tax - this.discount;

            this.pay_amount = this.balance_amount;
        },
        more_option_click(val) {
            if(val == 1) {
                if(this.tips_list.length > 0) {
                    this.unpaid_tip_dialog = true;
                } else {
                    this.save_unpaid();
                }
            } else if(val == 2) {
                this.invoice_dialog = true;
            }
        },
        save_unpaid() {
            let all_items = [];
            this.service_details.forEach(val => {
                let discount_amnt = 0;
                if(val.actual_amount > val.rate) {
                    discount_amnt = val.actual_amount - val.rate;
                }
                let data = {
                    item_code : val.item_code,
                    qty : val.qty,
                    actual_rate : val.actual_amount,
                    discounted_rate : val.amount,
                    discount_amount : discount_amnt,
                    staff : val.staff,
                    from_time : val.from_time,
                    to_time : val.to_time,
                    duration : val.duration,
                    service : val.service,
                }
                all_items.push(data);
            })

            var post_data = new FormData();
            post_data.append("appointment", this.appointment_id)
            post_data.append("customer", this.selected_client.id)
            post_data.append("items", JSON.stringify(all_items))
            post_data.append("payment_received_by", this.invoice_staff)
            post_data.append("invoice_note", this.invoice_notes)
            CommonApi.post_data('innosoft_salon.api.save_unpaid', post_data)
            .then(res => {
                if(res) {
                    this.$router.push('/invoice/'+res.data);
                }
            });
        },
        CompleteSale() {
            let tips = [];
            this.tips_list.forEach(val => {
                let tip_data = {
                    staff : val.staff_id,
                    tip : val.calculated_amount,
                }

                tips.push(tip_data);
            })

            let mode_pay = [];
            this.pay_types.forEach(val => {
                let pay_data = {
                    mode_of_payment : val.type,
                    amount : val.amount,
                    voucher : 0,
                    voucher_no : '',
                }

                mode_pay.push(pay_data);
            })
            let all_items = [];
            this.service_details.forEach(val => {
                let discount_amnt = 0;
                if(val.actual_amount > val.rate) {
                    discount_amnt = val.actual_amount - val.rate;
                }
                let data = {
                    item_code : val.item_code,
                    qty : val.qty,
                    actual_rate : val.actual_amount,
                    discounted_rate : val.amount,
                    discount_amount : discount_amnt,
                    staff : val.staff,
                    from_time : val.from_time,
                    to_time : val.to_time,
                    duration : val.duration,
                    service : val.service,
                }
                all_items.push(data);
            })

            var post_data = new FormData();
            post_data.append("appointment", this.appointment_id)
            post_data.append("customer", this.selected_client.id)
            post_data.append("items", JSON.stringify(all_items))
            post_data.append("mode_of_payment", JSON.stringify(mode_pay))
            post_data.append("tips", JSON.stringify(tips))
            post_data.append("payment_received_by", this.invoice_staff)
            post_data.append("invoice_note", this.invoice_notes)
            CommonApi.post_data('innosoft_salon.api.complete_sale', post_data)
            .then(res => {
                if(res) {
                    this.$router.push('/invoice/'+res.data);
                }
            });
        },
        back_to_payments() {
            this.payment_drawer = !this.payment_drawer;

            this.pay_types = [];

            let total = 0;
            this.tips_list.forEach(val => {
                total += val.calculated_amount;
            })

            this.applied_voucher = [];

            this.balance_amount = this.service_total_amount + total + this.tax - this.discount;
            this.pay_amount = this.balance_amount;
        },
        delete_pay(index) {
            this.pay_types.splice(index, 1);

            let total_pay_amount = 0;
            this.pay_types.forEach(val => {
                total_pay_amount += parseFloat(val.amount);
            })

            let amount_to_pay = this.service_total_amount + this.tax - this.discount;

            if(total_pay_amount >= amount_to_pay) {
                this.balance_amount = 0;
                this.payment_drawer = !this.payment_drawer;                
            } else {               
                this.balance_amount = amount_to_pay - total_pay_amount;
                this.pay_amount = this.balance_amount;
            } 
        },
    }
}
</script>
