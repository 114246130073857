<template>
	<v-card
		class="mx-auto"
		min-height="690px"
		max-height="690px"
		style="overflow-y: scroll;"
	>
		<v-card-title class="p-0 text-center">
			<v-app-bar class="item_dialog_title" color="white" flat>
				<v-btn
					icon
					@click="go_previous_step()"
					v-if="step != 1"
				>
					<v-icon medium>mdi-arrow-left</v-icon>
				</v-btn>
				<div v-if="step == 1"></div>
				<v-app-bar-title class="text-h6 font-weight-bold">{{ dialog_title }}</v-app-bar-title>
				<v-btn
					icon
					@click="close_dialog()"
				>
					<v-icon large>mdi-close</v-icon>
				</v-btn>
			</v-app-bar>
		</v-card-title>

		<v-divider></v-divider>

		<v-card color="grey lighten-5" flat v-if="step != 7 && step != 8 && step != 9">
			<!-- Search box -->
			<div class="pa-6 pb-0">
				<v-text-field
					solo
					placeholder="Scan barcode or search any item"
					prepend-inner-icon="mdi-magnify"
					v-model="search"
					:append-icon="search_clear_icon"
				></v-text-field>
			</div> 
			<!-- End search box -->
		</v-card>

		<v-window v-model="step">
			<!-- Select Item -->
			<v-window-item :value="1">
				<!-- Items -->
				<v-list class="pa-0">
					<template v-for="(item, i) in items">
						<v-list-item
							link
							:key="'item_'+i"
							class="py-1 px-7 font-weight-medium"
							@click="select_item(item)"
						>
							<v-list-item-content>
								<v-list-item-title>{{ item }}</v-list-item-title>
							</v-list-item-content>
							<v-list-item-icon>
								<v-icon>
									mdi-chevron-right
								</v-icon>
							</v-list-item-icon>
						</v-list-item>

						<v-divider :key="'item_div_'+i"></v-divider>
					</template>
				</v-list>
				<!-- End items -->
			</v-window-item>
			<!-- End Select Item -->

			<!-- Select product category -->
			<v-window-item :value="2">
				<!-- Product Category -->
				<v-list class="pa-0">
					<template v-for="(category, i) in product_categories">
						<v-list-item
							link
							:key="'prod_category_'+i"
							class="py-1 px-7 font-weight-medium"
							@click="select_prod_category(i)"
						>
							<v-list-item-content>
								<v-list-item-title>{{ i }}</v-list-item-title>
							</v-list-item-content>
							<v-list-item-icon>
								<v-icon>
									mdi-chevron-right
								</v-icon>
							</v-list-item-icon>
						</v-list-item>

						<v-divider :key="'prod_category_div_'+i"></v-divider>
					</template>
				</v-list>
				<!-- End product category -->
			</v-window-item>
			<!-- End Select product category -->

			<!-- Products -->
			<v-window-item :value="3">
				<template v-for="(product, i) in products">
					<v-list-item
						link
						:key="'product_'+i"
						class="py-1 px-7 font-weight-medium"
						@click="select_product(product)"
					>
						<v-list-item-content>
							<div class="d-flex flex-row">
								<div class="d-flex flex-column">
									<span class="font-weight-medium mb-1">{{ product.item_name }}</span>
									<span class="text-subtitle-1 text--disabled">{{ product.stock_balance+' in stock' }}</span>
								</div>
								<div class="d-flex flex-column justify-center ml-auto">ر.س{{ product.price }}</div>
							</div>
						</v-list-item-content>
					</v-list-item>

					<v-divider :key="'product_div_'+i"></v-divider>
				</template>
				<div v-if="!products" class="pa-16 text-center">
					<v-icon size="45px">$vuetify.icons.SearchSvg</v-icon>
					<p class="pt-4">No products or services found</p>
				</div>
			</v-window-item>
			<!-- End products -->

			<!-- Select service category -->
			<v-window-item :value="4">
				<!-- Service Category -->
				<v-list class="pa-0">
					<template v-for="(category, i) in service_categories">
						<v-list-item
							link
							:key="'serv_category_'+i"
							class="py-1 px-7 font-weight-medium"
							@click="select_serv_category(i)"
						>
							<v-list-item-content>
								<v-list-item-title>{{ i }}</v-list-item-title>
							</v-list-item-content>
							<v-list-item-icon>
								<v-icon>
									mdi-chevron-right
								</v-icon>
							</v-list-item-icon>
						</v-list-item>

						<v-divider :key="'serv_category_div_'+i"></v-divider>
					</template>
				</v-list>
				<!-- End service category -->
			</v-window-item>
			<!-- End Select service category -->

			<!-- Select service -->
			<v-window-item :value="5">
				<!-- Service -->
				<v-list class="pa-0">
					<template v-for="(service, i) in services">
						<v-list-item
							link
							:key="'service_'+i"
							class="py-1 px-7 font-weight-medium"
							@click="select_services(service)"
						>
							<v-list-item-content>
								<div class="d-flex flex-row">
									<div class="d-flex flex-column">
										<span class="font-weight-medium mb-1">{{ service.id }}</span>
										<span class="text-subtitle-1 text--disabled">{{ service.duration+'min' }}</span>
									</div>
									<div class="d-flex flex-column justify-center ml-auto">ر.س{{ service.rate }}</div>
								</div>
							</v-list-item-content>
						</v-list-item>

						<v-divider :key="'service_div_'+i"></v-divider>
					</template>
				</v-list>
				<!-- End service -->
			</v-window-item>
			<!-- End Select service -->

			<!-- Voucher Category -->
			<v-window-item :value="6">
				<v-list class="pa-0">
					<template v-for="(voucher, i) in voucher_cat">
						<v-list-item
							link
							:key="'vou_category_'+i"
							class="py-1 px-7 font-weight-medium"
							@click="select_voucher_category(voucher)"
						>
							<v-list-item-content>
								<v-list-item-title>{{ voucher }}</v-list-item-title>
							</v-list-item-content>
							<v-list-item-icon>
								<v-icon>
									mdi-chevron-right
								</v-icon>
							</v-list-item-icon>
						</v-list-item>

						<v-divider :key="'vou_category_div_'+i"></v-divider>
					</template>
				</v-list>
			</v-window-item>
			<!-- End voucher category -->

			<!-- Select voucher -->
			<v-window-item :value="7" class="pa-8 pb-0">
				<div v-for="(voucher, i) in vouchers" :key="'vou_'+i" class="pb-8">
					<div class="voucher_card" @click="select_voucher(voucher)">
						<div class="text-center">
							<div class="text-body-1">
								Voucher value
							</div>
							<p class="text-h4 font-weight-bold">ر.س{{ voucher.amount }}</p>
						</div>

						<div class="d-flex flex-row">
							<div class="d-flex flex-column">
								<span class="font-weight-medium mb-1">{{ voucher.gift_card_title }}</span>
								<span class="text-body-1">Redeem</span>
							</div>
							<div class="d-flex flex-column justify-center ml-auto text-right">
								<span class="font-weight-medium mb-1">ر.س{{ voucher.amount }}</span>
								<span class="text-body-1">{{ voucher.id }}</span>
							</div>
						</div>
					</div>
				</div>
				<div v-if="vouchers.length == 0" class="pa-16 d-flex flex-column justify-center align-center" style="height: 570px;">
					<p>
						<v-icon size="65px">$vuetify.icons.AddVoucherSvg</v-icon>
					</p>
					<p class="text-h5 mb-2 font-weight-medium">Add a voucher type</p>
					<p>You have no active voucher types.</p>
					<v-btn
						color="#101928"
						dark
						class="text-capitalize"
						large
					>
						Add voucher
					</v-btn>
				</div>
			</v-window-item>
			<!-- End select voucher -->

			<!-- Create voucher -->
			<v-window-item :value="8">
				<v-card>
					<v-card-text class="pa-6">
						<v-row>
							<v-col
								cols="12"
								class="pb-0 pt-0"
							>
								<label class="dialog_label">Included services</label>
								<v-text-field
									solo-inverted
									disabled
									v-model="voucher_services"
									class="disabled_text_clr"
								></v-text-field>
								<span @click="open_voucher_service()" class="edit_service primary--text">Edit</span>
							</v-col>
							<v-col
								cols="12"
								class="pt-0 pb-0"
							>
								<label class="dialog_label">Value</label>
								<v-text-field
									prefix="ر.س"
									solo
									v-model="voucher_value"
									type="number"
									@input="check_voucher_fields()"
								></v-text-field>
							</v-col>
							<v-col
								cols="12"
								class="pt-0 pb-0"
							>
								<label class="dialog_label">Retail price</label>
								<v-text-field
									prefix="ر.س"
									solo
									v-model="voucher_retail_price"
									type="number"
									@input="check_voucher_fields()"
									:rules="retail_price_rules"
								></v-text-field>
							</v-col>
							<v-col
								cols="12"
								class="pt-0 pb-0"
							>
								<label class="dialog_label">Valid for</label>
								<v-select
									:items="valid_for"
									v-model="voucher_valid"
									solo
									@input="check_voucher_fields()"
								></v-select>
							</v-col>
							<v-col
								cols="12"
								class="pt-0 pb-0"
							>
								<label class="dialog_label">Voucher name</label>
								<v-text-field
									solo
									v-model="voucher_name"
									counter="100"
									@input="check_voucher_fields()"
								></v-text-field>
							</v-col>
							<v-col
								cols="12"
								class="pt-0 pb-0"
							>
								<a class="hover_underline text-subtitle-1">Use advanced builder</a>
							</v-col>
						</v-row>
					</v-card-text>

					<v-divider></v-divider>

					<v-card-actions>
						<v-btn
							block
							large
							color="#101928"
							dark
							class="text-capitalize disabled_custom"
							style="font-size:18px;letter-spacing:0.6px;"
							:disabled="voucher_field_valid"
							@click="create_voucher()"
						>
							Sell voucher
						</v-btn>
					</v-card-actions>
				</v-card>
				
			</v-window-item>
			<!-- End create voucher -->

			<!-- Choose paid plan -->
			<v-window-item :value="9">
				<div class="pa-16 d-flex flex-column justify-center align-center" style="height: 570px;">
					<p>
						<v-icon size="65px">$vuetify.icons.EmptyStateSvg</v-icon>
					</p>
					<p class="text-h5 mb-2 font-weight-medium">Create a paid plan</p>
					<p>You have no active paid plans.</p>
				</div>
			</v-window-item>
			<!-- End choose paid plan -->
		</v-window>

		<v-dialog
			v-model="service_selection_dialog"
			persistent
			max-width="648px"
			transition="dialog-bottom-transition"
            class="top-dialog"
			scrollable
		>
			<v-card
				class="mx-auto"
				min-height="690px"
				max-height="690px"
			>
				<v-card-title class="py-0 px-4 text-center">
					<v-app-bar class="item_dialog_title" color="white" flat>
						<v-app-bar-title class="text-h5 font-weight-bold">Select services</v-app-bar-title>
						<v-btn
							medium
							icon
							@click="service_selection_dialog = false"
						>
							<v-icon medium>mdi-close</v-icon>
						</v-btn>
					</v-app-bar>
				</v-card-title>

				<v-divider></v-divider>

				<v-card-text class="pa-0">

					<div>
						<!-- Search box -->
						<div class="pa-6 px-8 pb-0">
							<v-text-field
								solo
								placeholder="Search services"
								prepend-inner-icon="mdi-magnify"
								v-model="search"
								:append-icon="search_clear_icon"
							></v-text-field>
						</div> 
						<!-- End search box -->
					</div>

					<v-divider></v-divider>

					<div class="px-7 pt-2">
						
						<v-checkbox v-model="is_all_service_selected" class="voucher_service" @change="select_all_service()">
							<template v-slot:label>
								<div>
									All services
									<v-chip
										class="ml-2 px-2"
										color="#f2f2f7"
										style="color:#80858b"
										pill
										small
									>
										{{ services_count }}
									</v-chip>
								</div>
							</template>
						</v-checkbox>

						<v-divider></v-divider>
						
					</div>

					<v-list class="pa-0">
						<template v-for="(category, i) in service_categories">
							<v-list-item
								link
								:key="'vou_serv_category_'+i"
								class="px-7 pb-1 pt-2"
							>
								<v-list-item-content>
									<v-list-item-title>
										<v-checkbox :value="i" v-model="selected_voucher_cats"  class="voucher_service remove_message mt-0" @change="vou_service_cat()"
										:indeterminate="check_all_service_selected(i) ? false : true"
										color="primary"
										>
											<template v-slot:label>
												<div>
													<span class="text-h6 font-weight-bold">{{ i }}</span>
													<v-chip
														class="ml-1 px-2"
														color="#f2f2f7"
														style="color:#80858b"
														small
													>
														{{ service_categories[i].length }}
													</v-chip>
												</div>
											</template>
										</v-checkbox>
									</v-list-item-title>
									
								</v-list-item-content>
							</v-list-item>

							<v-divider :key="'vou_serv_category_div_'+i"></v-divider>

							<div :key="'vou_serv_list_'+i">
								<template v-for="(service) in service_categories[i]">
									<v-list-item
										link
										:key="'vou_serv_'+service.id"
										class="py-2 px-8 font-weight-medium"
									>
										<v-list-item-content>

											<v-list-item-title class="vou_service_flex" style="font-size: 18px">
												<v-checkbox :value="service.id" v-model="selected_voucher_services" class="voucher_service remove_message mt-0" @change="on_service_change(i)">
													<template v-slot:label>
														<div class="d-flex flex-row">
															<div class="d-flex flex-column">
																<span class="font-weight-medium">{{ service.id }}</span>
																<span class="text-subtitle-1 text--disabled">{{ service.duration+'min' }}</span>
															</div>
															<div class="d-flex flex-column justify-center ml-auto">ر.س{{ service.rate }}</div>
														</div>
													</template>
												</v-checkbox>
											</v-list-item-title>

											<!-- <v-list-item-title style="font-size: 18px">
												<v-checkbox :value="service.id" v-model="selected_voucher_services" class="voucher_service remove_message mt-0">
													<template v-slot:label>
														<div>
															{{ service.id }}
														</div>
													</template>
												</v-checkbox>
											</v-list-item-title> -->
										</v-list-item-content>	

									</v-list-item>

									<v-divider style="max-width: calc(100% - 66px);margin: 0 auto;" :key="'vou_serv_div_'+service.id"></v-divider>
									
								</template>
							</div>

						</template>
					</v-list>
				</v-card-text>

				<v-divider></v-divider>
                <v-card-actions class="py-4 justify-end">
					<v-btn
						class="text-capitalize"
						outlined
						large
						width="120px"
						height="46px"
						style="font-size:18px;letter-spacing:0.6px;"
						@click="select_voucher_services()"
					>
						Cancel
					</v-btn>
                    <v-btn
                        large
                        color="#101928"
                        dark
                        class="text-capitalize ml-4 mr-5 disabled_custom"
						width="180px"
						height="46px"
						style="font-size:18px;letter-spacing:0.6px;"
						:disabled="selected_services_count == 0 ? true : false"
						@click="select_voucher_services()"
                    >
                        Select {{ selected_services_count }} services
                    </v-btn>
                </v-card-actions>
			</v-card>
		</v-dialog>

	</v-card>
</template>


<script>
import CommonApi from '@/services/CommonApi'
import moment from 'moment'
  export default {
    name : 'AddItem',
	
	props: [ "item_dialog", "checkout_items" ],

    data: () => ({
		search : '',
		step: 1,
		dialog_title : 'Select Item',
		items: [ 'Products', 'Services', 'Vouchers', 'Paid plans' ],
		selected_prod_cat : '',
		selected_serv_cat : '',
		vouchers : [],
		voucher_cat: [ 'Choose from vouchers', 'Create one-off voucher' ],
		valid_for : [ '14 days', '1 month', '2 months', '3 months', '6 months', '1 year', '3 years', '5 years', 'Forever'],
		search_clear_icon : '',
		service_selection_dialog : false,
		selected_voucher_services : [],
		selected_voucher_cats : [],
		is_all_service_selected : false,
		selected_services_count : 0,
		voucher_services : 'All services',
		voucher_value : 0,
		voucher_retail_price : 0,
		voucher_valid : '14 days',
		voucher_name : 'Gift Voucher',
		voucher_field_valid : true,
		retail_price_rules : [true]
    }),
	mounted() {
		this.is_all_service_selected = true;
		this.select_all_service();
	},
	computed: {
		service_categories() {
			if(this.search){
				let newObj = {};
				for (let key in this.checkout_items.services) {
					if (key.toString().toLowerCase().match(this.search)) {
						newObj[key] = this.checkout_items.services[key];
					}
				}
				return newObj;
			}else{
				return this.checkout_items.services;
			}
		},
		product_categories() {
			if(this.search){
				let newObj = {};
				for (let key in this.checkout_items.products) {
					if (key.toString().toLowerCase().match(this.search)) {
						newObj[key] = this.checkout_items.products[key];
					}
				}
				return newObj;
			}else{
				return this.checkout_items.products;
			}
		},
		services() {
			if(this.search || this.selected_serv_cat){
				if(this.search) {
					let newObj = {};
					for (let key in this.checkout_items.services[this.selected_serv_cat]) {
						if (this.checkout_items.services[this.selected_serv_cat][key].id.toString().toLowerCase().match(this.search) || this.checkout_items.services[this.selected_serv_cat][key].duration.toString().toLowerCase().match(this.search) || this.checkout_items.services[this.selected_serv_cat][key].rate.toString().toLowerCase().match(this.search)) {
							newObj[key] = this.checkout_items.services[this.selected_serv_cat][key];
						}
					}
					return newObj;
				} else {
					return this.checkout_items.services[this.selected_serv_cat]
				}
				
			}else{
				return this.checkout_items.services[this.selected_serv_cat];
			}
		},
		services_count() {
			let count = 0;
			for (let key in this.checkout_items.services) {
				for (let newkey in this.checkout_items.services[key]) {
					console.log("newkey", newkey)
					count++;
				}
			}
			return count;
		},
		products() {
			if(this.search || this.selected_prod_cat){
				if(this.search) {
					let newObj = {};
					for (let key in this.checkout_items.products[this.selected_prod_cat]) {
						if (this.checkout_items.products[this.selected_prod_cat][key].item_name.toString().toLowerCase().match(this.search) || this.checkout_items.products[this.selected_prod_cat][key].stock_balance.toString().toLowerCase().match(this.search)) {
							//  || this.checkout_items.products[this.selected_prod_cat][key].price.toString().toLowerCase().match(this.search)
							newObj[key] = this.checkout_items.products[this.selected_prod_cat][key];
						}
					}
					return newObj;
				} else {
					return this.checkout_items.products[this.selected_prod_cat]
				}
				
			}else{
				return this.checkout_items.products[this.selected_prod_cat];
			}
		},
		currentTitle () {
			switch (this.step) {
				case 1: return 'Sign-up'
				case 2: return 'Create a password'
				default: return 'Account created'
			}
		},
    },

	methods: {
		check_voucher_fields() {
			this.voucher_field_valid = true;
			this.retail_price_rules = [true];
			if (parseFloat(this.voucher_retail_price) > parseFloat(this.voucher_value)) {
				this.retail_price_rules = ['The retail price is higher than the voucher value'];
			}
			if(this.voucher_value != 0 && this.voucher_value != '' && this.voucher_retail_price != 0&& this.voucher_retail_price != '' && this.voucher_valid != '' && this.voucher_name != '') {
				this.voucher_field_valid = false;
			}
		},
		select_all_service() {
			this.selected_voucher_cats = [];
			this.selected_voucher_services = [];
			if(this.is_all_service_selected) {
				for (let key in this.checkout_items.services) {
					this.selected_voucher_cats.push(key);
					for (let newkey in this.checkout_items.services[key]) {
						if(!this.selected_voucher_services.includes(this.checkout_items.services[key][newkey].id)) {
							this.selected_voucher_services.push(this.checkout_items.services[key][newkey].id);
						}
					}
				}
			}
			this.count_services()
		},
		vou_service_cat() {
			this.selected_voucher_services = [];
			this.selected_voucher_cats.forEach(ele => {
				for (let key in this.checkout_items.services[ele]) {
					this.selected_voucher_services.push(this.checkout_items.services[ele][key].id);
				}
			});	
			this.count_services();
			this.check_service_selected_status()			
		},
		on_service_change(serv_cat) {
			let all_cat_serv_count = this.checkout_items.services[serv_cat].length;
			let selected_count = 0;
			for (let key in this.checkout_items.services[serv_cat]) {
				if(this.selected_voucher_services.includes(this.checkout_items.services[serv_cat][key].id)) {
					selected_count++;
				}
			}
			if(all_cat_serv_count == selected_count) {
				if(!this.selected_voucher_cats.includes(serv_cat)) {
					this.selected_voucher_cats.push(serv_cat);
				}
			}
			if(selected_count == 0) {
				let index = this.selected_voucher_cats.indexOf(serv_cat);
				if (index > -1) {
					this.selected_voucher_cats.splice(index, 1);
				}
			}
			this.count_services();
			this.check_service_selected_status()
		},
		open_voucher_service() {
			this.service_selection_dialog = true;
		},
		check_all_service_selected(i) {
			let all_cat_serv_count = this.checkout_items.services[i].length;
			let selected_count = 0;
			for (let key in this.checkout_items.services[i]) {
				if(this.selected_voucher_services.includes(this.checkout_items.services[i][key].id)) {
					selected_count++;
				}
			}
			if(all_cat_serv_count == selected_count || selected_count == 0) {
				return true;
			} else {
				return false;
			}	
		},
		count_services() {
			this.selected_services_count = this.selected_voucher_services.length;
		},
		check_service_selected_status() {
			let total_service_count = 0;
			let selected_service_count = 0;
			for (let key in this.checkout_items.services) {
				total_service_count += this.checkout_items.services[key].length;
				for (let newkey in this.checkout_items.services[key]) {
					if(this.selected_voucher_services.includes(this.checkout_items.services[key][newkey].id)) {
						selected_service_count++;
					}
				}
			}

			if(total_service_count == selected_service_count) {
				this.is_all_service_selected = true;
			} else {
				this.is_all_service_selected = false;
			}
		},
		select_voucher_services() {
			if(this.is_all_service_selected) {
				this.voucher_services = 'All services';
			} else {
				this.voucher_services = this.selected_services_count+' services';
			}
		
			this.service_selection_dialog = false;
		},
		create_voucher() {
			let start_date = new Date().toISOString().substr(0, 10);
			let split_date = this.voucher_valid.split(' ');
			let end_date = moment(start_date).add(parseInt(split_date[0]), split_date[1]).format('YYYY-MM-DD');

			const post_data = new FormData();
            post_data.append("gift_amount", this.voucher_value)
            post_data.append("retail_price", this.voucher_retail_price)
            post_data.append("start_date", start_date)
            post_data.append("end_date", end_date)
            post_data.append("voucher_name", this.voucher_name)
			if(this.voucher_services == 'All services') {
				post_data.append("all_service", 1)
			} else {
				post_data.append("all_service", 0)
			}
			let data_service = [];
			this.selected_voucher_services.forEach(val => {
				let service = { 'service' : val };
				data_service.push(service);
			})
			post_data.append("services", JSON.stringify(data_service))

            CommonApi.post_data('innosoft_salon.api.create_gift_voucher', post_data)
            .then(res => {
                if (res.status_code == 200) {
					this.step = 1;
					this.search = '';
					this.dialog_title = 'Select Item';

					let voucher_data = {
						amount : res.data.rate,
						actual_amount : this.voucher_value,
						gift_card_title : res.data.gift_card_title,
						id : res.data.item_code,
						item_code : res.data.item_code,
						end_date : res.data.end_date,
					}
					this.$emit('add-voucher', voucher_data);
                }
            })
		},
		select_services(service) {
			this.step = 1;
			this.search = '';
			this.dialog_title = 'Select Item';
			this.$emit('add-service', service);
		},
		select_product(product) {
			this.step = 1;
			this.search = '';
			this.dialog_title = 'Select Item';
			this.$emit('add-product', product);
		},
		select_voucher(voucher) {
			this.step = 1;
			this.search = '';
			this.dialog_title = 'Select Item';
			this.$emit('add-voucher', voucher);
		},
		test() {
			console.log("herehgzvdd")
		},
		close_dialog() {
			this.step = 1;
			this.search = '';
			this.dialog_title = 'Select Item';
			this.$emit('update-dialog', !this.item_dialog);
		},
		select_item(item) {
			if(item == 'Products') {
				this.dialog_title = 'Select Product';
				this.step = 2;
			} else if(item == 'Services') {
				this.dialog_title = 'Select Category';
				this.step = 4;
			} else if(item == 'Hair') {
				this.dialog_title = 'Hair';
				this.step = 5;
			} else if(item == 'Vouchers') {
				this.dialog_title = 'Vouchers';
				this.step = 6;
			} else if(item == 'Paid plans') {
				this.dialog_title = 'Choose A Paid Plan';
				this.step = 9;
			}
		},
		select_prod_category(category) {
			if(this.checkout_items.products[category]) {
				this.dialog_title = category;
				// this.products = this.checkout_items.products[category];
				this.selected_prod_cat = category;
				this.step = 3;
			} else {
				this.dialog_title = 'No Category';
				this.step = 3;
			}
		},
		select_serv_category(category) {
			if(this.checkout_items.services[category]) {
				this.dialog_title = category;
				this.selected_serv_cat = category;
				this.step = 5;
			} else {
				this.dialog_title = 'No Category';
				this.step = 5;
			}
		},
		select_voucher_category(cat) {
			if(cat == 'Choose from vouchers') {
				this.vouchers = this.checkout_items.gift_card;
				this.dialog_title = 'Select Voucher';
				this.step = 7;
			} else if(cat == 'Create one-off voucher') {
				this.dialog_title = 'Create One-Off Voucher';
				this.step = 8;
			}
		},
		go_previous_step() {
			if(this.step == 4 || this.step == 6 || this.step == 9) {
				this.step = 1;
			} else if(this.step == 8){
				this.step = 6;
			} else {
				this.step = this.step - 1;
			}
			
			if(this.step == 1) {
				this.dialog_title = 'Select Item';
			} else if(this.step == 2) {
				this.dialog_title = 'Select Product';
			} else if(this.step == 4) {
				this.dialog_title = 'Select Category';
			} else if(this.step == 6) {
				this.dialog_title = 'Vouchers';
			}
		}
	},
  }
</script>
<style lang="scss">
	.v-app-bar-title__content {
		width: auto !important;
	}
</style>